document.addEventListener('DOMContentLoaded', function () {
  const ajaxUrl = `${window.location.origin}/wp/wp-admin/admin-ajax.php`
  const showTimesContainer = document.getElementById('show-times-container')
  if (!showTimesContainer) {
    return
  }
  const movieDropdownOptions = document.querySelectorAll('.movie-option')
  const showAllMoviesBtn = document.getElementById('show-all-movies')
  const movieCalendarInput = document.getElementById('calendar-input')
   // Set the placeholder text of the date input
   movieCalendarInput.setAttribute('placeholder', 'Välj datum');

   // Set the value of the date input to empty when the page loads
   movieCalendarInput.value = '';

  function fetchAllShowTimes() {
    fetch(ajaxUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        action: 'get_available_movies',
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error fetching all show times')
        }
        return response.json()
      })
      .then((data) => {
        if (data && data.success && data.data && data.data.html) {
          showTimesContainer.innerHTML = data.data.html
        } else {
          console.error('Error fetching all show times: Invalid response format')
        }
      })
      .catch((error) => {
        console.error('Error fetching all show times:', error)
      })
  }

  function fetchShowTimes(selectedMovieId, selectedDate) {
    fetch(ajaxUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        action: 'get_available_movies',
        selectedMovie: selectedMovieId,
        selectedDate: selectedDate,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error fetching show times')
        }
        return response.json()
      })
      .then((data) => {
        if (data && data.success && data.data && data.data.html) {
          showTimesContainer.innerHTML = data.data.html
        } else if (data && data.success === false && data.data && data.data.message) {
          showTimesContainer.innerHTML = `<p>${data.data.message}</p>`
        } else {
          console.error('Error fetching show times: Invalid response format')
        }
      })
      .catch((error) => {
        console.error('Error fetching show times:', error)
      })
  }

  function resetSelectedMovie() {
    const selectedMovieText = 'Välj Film';
    document.querySelector('#movie-dropdown span').innerText = selectedMovieText;
  }

  fetchAllShowTimes()

  movieDropdownOptions.forEach((option) => {
    option.addEventListener('click', function () {
      const selectedMovieId = this.getAttribute('data-movie-id')
      const selectedDate = movieCalendarInput.value
      fetchShowTimes(selectedMovieId, selectedDate)
    })
  })

  movieCalendarInput.addEventListener('change', function () {
    const selectedDate = movieCalendarInput.value;
    if (selectedDate) {
        const selectedMovieId = null;
        resetSelectedMovie();
        fetchShowTimes(selectedMovieId, selectedDate);
    }
});

  showAllMoviesBtn.addEventListener('click', function () {
    fetchAllShowTimes()
  })
})
